import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const List = makeShortcode("List");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-the-business-of-games",
      "style": {
        "position": "relative"
      }
    }}>{`📊 The Business of Games`}<a parentName="h1" {...{
        "href": "#-the-business-of-games",
        "aria-label": " the business of games permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <blockquote>
      <p parentName="blockquote">{`Too often, the people disrupting any industry don't understand deeply what it is they're disrupting. This is definitely the case with cryptocurrencies and the current financial system. It really is well worth your time to stop and become familiar with more history so that you can understand why we are where we are, what led us here, and - only then - what solutions might actually benefit you and those you care about.`}</p>
    </blockquote>
    <p>{`The Learn Track's module on `}<a parentName="p" {...{
        "href": "/learn/module-2"
      }}><strong parentName="a">{`The Global Financial System`}</strong></a>{` revolves around the history of debt, introducing new perspectives on `}<a parentName="p" {...{
        "href": "/learn/module-2/money-speech"
      }}>{`money and speech`}</a>{`.`}</p>
    <p>{`The origins of systems are usually surprising, as exemplified by these three points from  `}<a parentName="p" {...{
        "href": "/learn/module-2/debt"
      }}><strong parentName="a">{`David Graeber's talk`}</strong></a>{`:`}</p>
    <ol>
      <li parentName="ol">{`Virtual money systems came before physical currency.`}</li>
      <li parentName="ol">{`Physical currency was invented to pay soldiers.`}</li>
      <li parentName="ol">{`World religions start up as peace movements against materialism due to money.`}</li>
    </ol>
    <p>{`Our financial systems represent the accumulation of centuries spent moralising about violent inequality. They are often archaic, and ill-suited to contemporary needs. That they take the form we now see them in is not the result of some inevitable, divine diktat: they are products of their history, which is why understanding it deeply provides fresh and new insights into what "improvement" actually looks like. We should look at games in a similar light, ask why things are the way they are, and see where we can change them for the better.`}</p>
    <h2 {...{
      "id": "the-march-of-technology",
      "style": {
        "position": "relative"
      }
    }}>{`The March of Technology`}<a parentName="h2" {...{
        "href": "#the-march-of-technology",
        "aria-label": "the march of technology permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`If we're looking to disrupt games, we also need to know the deep background. Below is a `}<Link to="https://medium.com/@polats/game-industry-timeline-from-1997-2018-4b5adfa76ecc" mdxType="Link">{`curated timeline of articles`}</Link>{` from `}<a parentName="p" {...{
        "href": "/track-gaming/module-1/crafted/#industry-references"
      }}>{`Gamasutra`}</a>{` that gives a good overview of how the game industry evolved between 1997 and 2018. `}</p>
    <p>{`Mouse-over / tap on the circles to see a relevant quote from each article, along with their amusingly anachronistic titles such as `}<em parentName="p">{`"Internet Game Design"`}</em>{` and `}<em parentName="p">{`"What's the ROI on my Wireless 3G Game?"`}</em></p>
    <iframe src="https://public.tableau.com/views/GameIndustryTimeline/GameIndustryTimelinebasedonselectGamasutraArticles?:showVizHome=no&:embed=true" width="100%" height="450"></iframe>
    <p>{`The articles are colored `}<span style={{
        "color": "red"
      }}>{`red`}</span>{` if they mention a market contraction, `}<span style={{
        "color": "green"
      }}>{`green`}</span>{` if it signals market growth, and `}<span style={{
        "color": "blue"
      }}>{`blue`}</span>{` if it's informational.`}</p>
    <p><a parentName="p" {...{
        "href": "/track-gaming/module-2/crafted/#the-video-game-crash-of-1983"
      }}>{`The history of video games goes back way farther than this`}</a>{`, but we can look back just until 1997 to start identifying some patterns. For one, we see that the video game industry is greatly affected by technology, more so than most industries. In its journey to become the `}<a parentName="p" {...{
        "href": "/track-gaming/module-2/crafted/#superdatas-2020-year-in-review"
      }}>{`$140 billion juggernaut`}</a>{` it is today, it ebbs and flows during game console transitions.`}</p>
    <p>{`More importantly, we see how it's greatly affected by peripheral technologies. In 2006, we see analysts starting to predict how game revenues will double due to mobile devices, a year before the iPhone releases. In 2009, we start seeing the success of social games as they surge in popularity along with Facebook. `}</p>
    <p>{`Perhaps 5 years from now, in 2026, we'll look back to today and see how Web 3 was the start of the next big wave.`}</p>
    <h2 {...{
      "id": "♂️-riding-the-wave",
      "style": {
        "position": "relative"
      }
    }}>{`🏄‍♂️ Riding The Wave`}<a parentName="h2" {...{
        "href": "#%E2%99%82%EF%B8%8F-riding-the-wave",
        "aria-label": "♂️ riding the wave permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Or maybe we'll look back and see that Web 3 was the next big wipeout. There's no shortage of these in the timeline as well: `}<a parentName="p" {...{
        "href": "/track-gaming/module-2/crafted/#psp-mini-falls"
      }}>{`The PSP Mini`}</a>{`, the `}<em parentName="p">{`Star Wars: The Old Republic MMO`}</em>{`, the `}<a parentName="p" {...{
        "href": "/track-gaming/module-2/crafted/#the-ouya-kickstarter"
      }}>{`ill-fated Ouya`}</a>{`.`}</p>
    <p>{`It could also be something in between, a blip of momentary success like Habbo Hotel, Neopets, or The `}<a parentName="p" {...{
        "href": "/track-gaming/module-2/crafted/#the-toys-to-life-bubble"
      }}>{`Toys-to-Life genre`}</a>{` popularized by Skylanders.`}</p>
    <p>{`Another way to identify important patterns is to ask how and why a game becomes, to use another outdated term, the `}<em parentName="p">{`killer app`}</em>{`. What Angry Birds was for the iPhone, Farmville was for Social Games, and Pokemon Go was for AR. These games were the perfect conflux of platform and game, when the content was so suited for the medium that it affected the platform's trajectory.`}</p>
    <h2 {...{
      "id": "on-platforms-and-meaningful-change",
      "style": {
        "position": "relative"
      }
    }}>{`On Platforms and Meaningful Change`}<a parentName="h2" {...{
        "href": "#on-platforms-and-meaningful-change",
        "aria-label": "on platforms and meaningful change permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`One last pattern to point out is that, even as game developers are able to become massively successful with one game, the industry will eventually consolidate value around aggregators and platforms. As each technology cycle matures, it's the publishers like Tencent and the platforms like the App Store which become the arbiters of success. We can see this in the App Store rankings, which `}<a parentName="p" {...{
        "href": "/track-gaming/module-2/curated/#3-disruptive-game-design-trends-to-look-forward-to-in-2020"
      }}>{`for more than 4 years`}</a>{` have largely stayed the same.`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 Meaningful change comes from how technologies or start ups change the power dynamics of the industry itself. `}</p>
    </blockquote>
    <p>{`Fortunately this has also happened a few times in our timeline. We saw it when crowdfunding platforms like Kickstarter changed how we made games to the current early-access model. It happened when mobile phones changed the model from $60 publisher-backed retail games to independently developed short-form games. It happened when free-to-play ad-monetized games became the most popular digital form of entertainment that we consume today.`}</p>
    <h2 {...{
      "id": "web-3s-legacy",
      "style": {
        "position": "relative"
      }
    }}>{`Web 3's Legacy`}<a parentName="h2" {...{
        "href": "#web-3s-legacy",
        "aria-label": "web 3s legacy permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`New technology has the power to both decentralize and consolidate, and it's the vast historical context of the video game industry that we need to be aware of when making our Web 3 games.`}</p>
    <p>{`As Web 3 builders, we have a unique opportunity to enact meaningful change more; so than others before us. Our industry has brought together game developers, economists, artists, and open-source thinkers. We have new radical economic tools such as `}<a parentName="p" {...{
        "href": "/track-gaming/module-2/crafted/#wtf-is-qf"
      }}>{`quadratic funding`}</a>{` to help us redesign the very foundations of value.`}</p>
    <p>{`The legacy of Web 3 will be up to us.`}</p>
    <h1 {...{
      "id": "-a-game-each-week",
      "style": {
        "position": "relative"
      }
    }}>{`🎮 A Game Each Week`}<a parentName="h1" {...{
        "href": "#-a-game-each-week",
        "aria-label": " a game each week permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <List mdxType="List">
      <Link to="https://play.google.com/store/apps/details?id=net.kairosoft.android.gamedev3en&hl=en&gl=US" mdxType="Link">
        <h4 {...{
          "id": "game-dev-story",
          "style": {
            "position": "relative"
          }
        }}>{`Game Dev Story`}<a parentName="h4" {...{
            "href": "#game-dev-story",
            "aria-label": "game dev story permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Made by the management simulator maestros at Kairosoft, this is a fun and semi-accurate homage to the history of game development.`}</p>
        <Image src="/images/gaming-img/gamedevstory.png" alt="Game Dev Story" mdxType="Image" />
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      